const llmModelsNames = [
  {
    name: "GPT-4",
    id: "gpt-4",
  },
  {
    name: "GPT-4o",
    id: "gpt-4o",
  },
  {
    name: "GPT-3.5 Turbo",
    id: "gpt-3.5-turbo-0125",
  },
  {
    name: "LLaMA 3 8b",
    id: "llama3-8b-8192",
  },
  {
    name: "LLaMA 3 70b",
    id: "llama3-70b-8192",
  },
  {
    name: "Mixtral 8x7b",
    id: "mixtral-8x7b-32768",
  },
  {
    name: "Gemma 7b",
    id: "gemma-7b-it",
  },
];

const embeddingsModelNames = [
  {
    name: "OpenAI",
    id: "openai",
  },
  {
    name: "HuggingFace",
    id: "huggingface",
  },
];

const llmServicesNames = [
  {
    name: "OpenAI",
    id: "chat_gpt",
  },
  {
    name: "Llama 2",
    id: "llama2",
  },
  {
    name: "Groq",
    id: "groq",
  },
];



module.exports = {
  llmModelsNames,
  embeddingsModelNames,
  llmServicesNames,
};
