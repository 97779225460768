import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Button } from "@mui/material";

const DeleteLanguageModal = ({ open, onClose, onSave, languages }) => {
  const { t } = useTranslation("translations");
  const [language, setLanguage] = useState("");
  const [deleteTranslations, setDeleteTranslations] = useState(true);

  const getOptionLanguagesLabel = useMemo(() => (option) => option.buttonText, []);

  const handleLanguageChange = useCallback((event, newValue) => {
    if (newValue !== null) {
      let newLanguage = newValue.language;
      setLanguage(newLanguage);
    } else {
      setLanguage("");
    }
  }, []);


  useEffect(() => {
    setLanguage("");
    setDeleteTranslations(true);
  }, [open]);


  const renderInputLanguages = useMemo(
    () =>
      function renderInputLanguagesTextfield(params) {
        return <TextField variant="standard" {...params} label={t("language")} />;
      },
  );

  const handleCheckboxChange = (event) => {
    setDeleteTranslations(event.target.checked);
  };

  const valueLanguage = useMemo(
    () => languages.find((x) => x.language === language),
    [language, languages],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth >
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem"}}>
        {t("deleteLanguage")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
      
        <Autocomplete
          variant="standard"
          options={languages}
          id={"routes-editModal-auth"}
          value={valueLanguage}
          getOptionLabel={getOptionLanguagesLabel}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleLanguageChange}
          renderInput={renderInputLanguages}
        />

        <FormControlLabel
          control={<Checkbox checked={deleteTranslations} onChange={handleCheckboxChange} />}
          label={t("deleteTranslations")}
        />


      </Box>
      <DialogActions>
        <Button onClick={onClose}>{t("modalCancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={language===""}
          onClick={() =>
            onSave({
              language, deleteTranslations,
            })
          }>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteLanguageModal;
