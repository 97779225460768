import React, { useEffect, useState } from "react";
import {Box, Grid} from "@mui/material";
import AuthItem from "./AuthItem";


const ApiAuth = ({auths, modalDeleteAuth, saveAuth}) => {
  const [arrayAuth, setArrayAuth] = useState([]);

  useEffect(() => {
    if (auths?.length >= 0){
      const initialAuth = auths.map((auth) => ({
        ...auth,
        editing: auth.editing !== undefined ? auth.editing : false,
      }));
      setArrayAuth(initialAuth);
    } else {
      setArrayAuth([]);
    }
  }, [auths]);

  return (
    <Grid item xl={1} columns={1} sx={{ display: "flex", flexDirection: "column", alignItems:"flex-start", justifyContent:"flex-start"}} >
      {
        (arrayAuth.length>0) ? arrayAuth.map ((auth, index) => {
          return (
            <Box key={index} xl={2} sx={{padding: "16px",marginLeft:"8px", marginBottom: "24px", borderRadius: "5px", width: "30vw", border: "1px solid #4e8cff" }}>
              <AuthItem key={index} _id={index} auth={ {...auth }} arrayAuth={arrayAuth} modalDeleteAuth={modalDeleteAuth} saveAuth={saveAuth}/>
            </Box>
          );   
        }) : null
      }
    </Grid>
  );
};

export default ApiAuth;