import { Button, TextField, Checkbox, Dialog } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { post } from "../../shared/http/httpService";
import { login as loginAction } from "../../shared/redux/authSlice";
import { validateEmail } from "../../shared/helper/validations";

import styles from "./Login.module.css";

//svgs
import { G274Icon } from "../../assets/svgs/darwin-logo";
import { G278Icon } from "../../assets/svgs/darwin-logo";
import { G282Icon } from "../../assets/svgs/darwin-logo";
import { G286Icon } from "../../assets/svgs/darwin-logo";
import { Path290Icon } from "../../assets/svgs/darwin-logo";
import { G292Icon } from "../../assets/svgs/darwin-logo";
import { DotIcon } from "../../assets/svgs/darwin-logo";
import { EmailIcon } from "../../assets/svgs/darwin-logo";
import { CopyIcon } from "../../assets/svgs/darwin-logo";
//images
import carrouselImage1 from "../../assets/images/image.jfif";
import carrouselImage2 from "../../assets/images/image2.jfif";
import carrouselImage3 from "../../assets/images/image3.jfif";

const firstText = [
  "Mantente en contacto con tus clientes",
  "Personaliza la atención de tus clientes y",
  "Mejora la experiencia de tus clientes y",
];
const secondText = [
  "las 24 horas los 7 días de la semana.",
  "ofrece respuestas inmediatas.",
  "garantiza mejores resultados.",
];

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [errorModal, setErrorModal] = useState("");
  const [error2FaModal, setError2FaModal] = useState("");
  const [dialogForgotPassword, setDialogForgotPassword] = useState(0);
  const [dialogPretoken, setDialogPretoken] = useState(false);
  const [dialogAuth2FA, setDialogAuth2FA] = useState(false);
  const [dialogContactSupport, setDialogContactSupport] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [token2Fa, setToken2Fa] = useState(Array(6).fill(""));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rememberIp, setRememberIp] = useState(false);
  const { t, i18n } = useTranslation();
  const images = [carrouselImage1, carrouselImage2, carrouselImage3];

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      index = (index + 1) % 3; // Tenemos 3 imágenes
      setCurrentImage(index);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleLogin = () => {
    const body = {
      username,
      password,
      token2Fa: token2Fa.join(""),
    };

    if (rememberIp) body.rememberIp = true;
    if (Object.keys(errors).length > 0) {
      validate();
      return;
    }
    post("/login", body)
      .then((response) => {
        dispatch(loginAction(response));
        i18n.changeLanguage(response.language);
        navigate("/");
      })
      .catch((errorMessage) => {
        if (errorMessage.status === 401) {
          if (
            errorMessage.message.message !== "Invalid login credentials" &&
            errorMessage.message.message !== "Invalid password"
          ) {
            setDialogPretoken(true);
            setDialogAuth2FA(true);
          } else {
            enqueueSnackbar(t("login:invalidLogin"), {
              variant: "error",
            });
          }

          if (errorMessage.message === "Incorrect 2FA Token") {
            let error2FaModal = {};
            error2FaModal.token2Fa = t("login:invalidToken2Fa");
          }
        } else {
          if (errorMessage.msg === "Invalid two factor token") {
            let error2FaModal = {};
            error2FaModal.token2Fa = t("login:invalidToken2Fa");
            setError2FaModal(error2FaModal);
          }
        }
      });
  };

  const recoverPassword = () => {
    const body = {
      email,
    };
    post("/login/recoverPassword", body)
      .then(() => {})
      .catch((error) => {
        if (error.message && error.status) {
          if (error.status === 429)
            enqueueSnackbar(t("login:tooManyPassswordRecoveries"));
          else
            enqueueSnackbar(t("login:succesRecoverPassword"), {
              varian: "succes",
            });
        } else
          enqueueSnackbar(t("login:invalidRecoverPassword"), {
            variant: "error",
          });
      })
      .finally(() => {
        setDialogForgotPassword(2);
      });
  };

  useEffect(() => {
    validate();
  }, [username, password, i18n.language]);

  useEffect(() => {
    setErrors({ start: "" });
  }, []);

  useEffect(() => {
    setErrorModal({});
  }, [dialogForgotPassword]);

  useEffect(() => {
    setErrorModal({});
    validateModal();
  }, [email]);

  const validate = () => {
    let errors = {};

    if (!username || username === "") {
      errors.username = t("login:validationUserEmpty");
    }

    if (!password || password === "") {
      errors.password = t("login:validationPasswordEmpty");
    }

    return setErrors(errors);
  };

  const validateModal = () => {
    let errorModal = {};

    if (email === "") {
      errorModal.email = true;
      setErrorModal(errorModal);
      return false;
    }

    if (!validateEmail(email)) {
      errorModal.email = true;
      setErrorModal(errorModal);
      return false;
    }

    return true;
  };

  const validate2FaModal = () => {
    let error2FaModal = {};

    if (token2Fa === "") {
      error2FaModal.token2Fa = t("login:validationTokenEmpty");
      return false;
    }

    return true;
  };

  function handleInput(index, value) {
    const values = [...token2Fa];
    values[index] = value;
    setToken2Fa(values);
    setError2FaModal("");
  }

  function handleBackspace(index) {
    const values = [...token2Fa];
    if (index >= 0) {
      values[index] = "";
      setToken2Fa(values);
      setError2FaModal("");
      if (index > 0) {
        const previousInput = document.querySelector(
          `input[name='${index - 1}']`,
        );
        if (previousInput !== null) {
          previousInput.focus();
        }
      }
    }
  }

  function handlePaste(e) {
    const paste = e.clipboardData.getData("text");
    if (paste.length === 6) {
      const values = paste.split("");
      setToken2Fa(values);
    }
  }

  return (
    <div className={styles.loginStart}>
      <div className={styles.carrusel}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`${styles.carruselImg} ${
              currentImage !== index ? styles.next : ""
            }`}>
            <img src={image} alt="carrousel" />
            <div className={styles.carruselFilter}></div>
          </div>
        ))}
        <div className={styles.body}>
          <div className={styles.textLogin}>
            <div className={styles.textContent}>
              <div className={styles.loremIpsumDolorContainer}>
                <span className={styles.carrouselFirstText}>
                  {firstText[currentImage]}
                </span>
                <span className={styles.carrouselSecondText}>
                  {" "}
                  {secondText[currentImage]}
                </span>
              </div>
              <div className={styles.carrouselThirdText}>
                Darwin by Evoltis.
              </div>
            </div>
          </div>
          <div className={styles.slider}>
            <div
              className={
                currentImage === 0 ? styles.sliderChild : styles.sliderItem
              }
            />
            <div
              className={
                currentImage === 1 ? styles.sliderChild : styles.sliderItem
              }
            />
            <div
              className={
                currentImage === 2 ? styles.sliderChild : styles.sliderItem
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.isotipoDarwin}>
          <G274Icon className={styles.g274Icon} alt="" />
          <G278Icon className={styles.g278Icon} alt="" />
          <G282Icon className={styles.g282Icon} alt="" />
          <G286Icon className={styles.g286Icon} alt="" />
          <Path290Icon className={styles.path290Icon} alt="" />
          <G292Icon className={styles.g292Icon} alt="" />
          <DotIcon className={styles.dotIcon} alt="" />
        </div>
        {!dialogAuth2FA && dialogForgotPassword == 0 && (
          <div className={styles.content1}>
            <div className={styles.content2}>
              <div className={styles.ttuloDescripcion}>
                <div className={styles.emoji}>👋</div>
                <div className={styles.tituloDescripcion}>
                  <div className={styles.titulo1}>
                    <p className={styles.titulo1}>Te damos la</p>
                    <p className={styles.titulo2}>bienvenida</p>
                  </div>
                  <div className={styles.elUniversoDe}>
                    El universo de Darwin está listo para que explores todas sus
                    funcionalidades y potencies la experiencia de tus clientes.
                  </div>
                </div>
              </div>
              <div className={styles.formRecordarme}>
                <div className={styles.form}>
                  <TextField
                    label="Usuario"
                    fullWidth
                    variant="filled"
                    id={"login_input_username"}
                    className={styles.textField}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                  <TextField
                    label="Contraseña"
                    fullWidth
                    type="password"
                    variant="filled"
                    id={"login_input_password"}
                    className={styles.textField}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                </div>
                <div className={styles.recordarmeForgot}>
                  <div className={styles.recordarme}>
                    <Checkbox
                      size="small"
                      id="remember_me_check"
                      onChange={(e) => setRememberIp(e.target.checked)}
                      value={rememberIp}
                      sx={{
                        color: "#2C2F88",
                        "&.Mui-checked": { color: "#2C2F88" },
                      }}
                    />
                    <div className="recordarme">Recuérdame</div>
                  </div>
                  <Button
                    onClick={() => setDialogForgotPassword(1)}
                    id="forgot-password"
                    sx={{
                      color: "#2C2F88",
                      textTransform: "none",
                      fontSize: "14px",
                    }}>
                    Olvidé mi contraseña
                  </Button>
                </div>
              </div>
              <div className={styles.botForm}>
                <Button
                  variant="contained"
                  id={"login_button"}
                  onClick={handleLogin}
                  sx={{
                    fontSize: "20px",
                    textAlign: "center",
                    minWidth: "248px",
                    width: "100%",
                  }}>
                  Iniciar sesión
                </Button>
                <div className={styles.necesitasAyudaParaIniciarSParent}>
                  <div className={styles.teDamosLaContainer}>
                    <span>¿Necesitas ayuda para iniciar sesión?</span>
                  </div>
                  <Button
                    onClick={() => setDialogContactSupport(true)}
                    sx={{
                      color: "#2C2F88",
                      textTransform: "none",
                      marginLeft: "-5px",
                    }}>
                    Contáctate con soporte.
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {dialogAuth2FA && (
          <div className={styles.content1}>
            <div className={styles.content2}>
              <div className={styles.ttuloDescripcion}>
                <div className={styles.emoji}>✍</div>
                <div className={styles.tituloDescripcion}>
                  <span>Verifica tu cuenta</span>
                </div>
                <div className={styles.twoFAingresaYVerificaElCdigoQParent}>
                  <div className={styles.twoFAingresaYVerifica}>
                    Por tu seguridad, enviamos un código a tu correo. Ingrésalo
                    aquí para que podamos verificar tu cuenta
                  </div>
                </div>
              </div>
              <div className={styles.twoFAform}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  {token2Fa.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => {
                        handleInput(index, e.target.value);
                        if (e.target.value) {
                          const nextSibling = document.querySelector(
                            `input[name='${index + 1}']`,
                          );
                          if (nextSibling !== null) {
                            nextSibling.focus();
                          }
                        }
                      }}
                      onPaste={handlePaste}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") handleBackspace(index);
                        if (
                          e.key === "Enter" &&
                          token2Fa.length === 6 &&
                          token2Fa.includes("") === false
                        ) {
                          if (validate2FaModal()) handleLogin();
                        }
                      }}
                      className={`${styles.twoFAverifyCode} ${
                        error2FaModal ? styles.error : ""
                      }`}
                      name={index}
                      id={`#token2fa_${index}`}
                      autoFocus={index === 0}
                    />
                  ))}
                </div>
                {error2FaModal ? 
                  <div className={styles.helper2FaError}>
                    {error2FaModal.token2Fa}
                  </div> 
                  : null
                }
                <div className={styles.twoFAnoRecibisteElContainer}>
                  <span>¿No recibiste el código?</span>
                  <span className={styles.twoFAclickParaReenviar}>
                    {" "}
                    Click para reenviar
                  </span>
                </div>
              </div>
              <div className={styles.twoFAbotForm}>
                <Button
                  variant="contained"
                  id={"login_button"}
                  onClick={() => {
                    if (validate2FaModal()) handleLogin();
                  }}
                  disabled={token2Fa.includes("")}
                  sx={{
                    fontSize: "20px",
                    textAlign: "center",
                    minWidth: "248px",
                    width: "100%",
                  }}>
                  Iniciar sesión
                </Button>
                <Button
                  onClick={() => {
                    setToken2Fa(Array(6).fill(""));
                    setDialogAuth2FA(false);
                    setError2FaModal("");
                  }}
                  sx={{
                    textTransform: "none",
                    color: "#2C2F88",
                  }}>
                  Volver a inicio de sesión
                </Button>
              </div>
            </div>
          </div>
        )}
        {dialogForgotPassword == 1 && (
          <div className={styles.content1}>
            <div className={styles.content2}>
              <div className={styles.ttuloDescripcion}>
                <div className={styles.emoji}>🤔</div>
                <div className={styles.tituloDescripcion}>
                  <div className={styles.titulo1}>
                    <p className={styles.titulo1}>¿Olvidaste tu</p>
                    <p className={styles.titulo2}>contraseña?</p>
                  </div>
                  <div className={styles.elUniversoDe}>
                    Te enviaremos una contraseña nueva por correo electrónico.
                    ¿A dónde quieres recibirla?
                  </div>
                </div>
              </div>
              <div className={styles.formRecordarme}>
                <div className={styles.form}>
                  <TextField
                    id="recovery-pass-email"
                    label="Correo electrónico"
                    fullWidth
                    variant="filled"
                    className={styles.textField}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (validateModal()) recoverPassword();
                      }
                    }}
                  />
                </div>
              </div>
              <div className={styles.twoFAbotForm}>
                <Button
                  variant="contained"
                  type="email"
                  id="login_send_button"
                  onClick={() => {
                    if (validateModal()) recoverPassword();
                  }}
                  disabled={errorModal.email || email === ""}
                  sx={{
                    fontSize: "20px",
                    textAlign: "center",
                    minWidth: "248px",
                    width: "100%",
                  }}>
                  Enviar contraseña
                </Button>
                <Button
                  id="login_cancel_button"
                  onClick={() => {
                    setEmail("");
                    setDialogForgotPassword(0);
                  }}
                  sx={{
                    color: "#2C2F88",
                  }}>
                  Volver a inicio de sesión
                </Button>
              </div>
            </div>
          </div>
        )}
        {dialogForgotPassword == 2 && (
          <div className={styles.content1}>
            <div className={styles.content2}>
              <div className={styles.ttuloDescripcion}>
                <div className={styles.emoji}>😌</div>
                <div className={styles.tituloDescripcion}>
                  <div className={styles.titulo1}>
                    <p className={styles.titulo2}>¡Todo listo!</p>
                  </div>
                  <div className={styles.elUniversoDe}>
                    Puedes iniciar sesión con la nueva contraseña que enviamos a
                    tu correo electrónico.
                  </div>
                </div>
              </div>
              <div className={styles.twoFAbotForm}>
                <Button
                  variant="contained"
                  id="login_button"
                  onClick={() => {
                    setEmail("");
                    setDialogForgotPassword(0);
                  }}
                  sx={{
                    fontSize: "20px",
                    textAlign: "center",
                    minWidth: "248px",
                    width: "100%",
                  }}>
                  Volver a inicio de sesión
                </Button>
              </div>
              <div className={styles.necesitasAyudaParaIniciarSParent}>
                <div className={styles.teDamosLaContainer}>
                  <span>¿No recibiste la contraseña?</span>
                </div>
                <Button
                  onClick={() => {
                    if (validateModal()) recoverPassword();
                  }}
                  sx={{
                    color: "#2C2F88",
                    textTransform: "none",
                    marginLeft: "-5px",
                  }}>
                  Click para reenviar
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={dialogContactSupport}
        onClose={() => setDialogContactSupport(false)}>
        <div className={styles.dialogContactSupport}>
          <div className={styles.titleDescription}>
            <EmailIcon className={styles.emailIcon} alt="" />
            <div className={styles.headline}>Contáctate con soporte</div>
            <div className={styles.supportingText}>
              El equipo de Darwin está para ayudarte con lo que necesites. Envía
              un correo a clientedarwin@evoltis.com con tu consulta.
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              onClick={() => setDialogContactSupport(false)}
              sx={{
                color: "#2C2F88",
                textTransform: "none",
              }}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText("clientedarwin@evoltis.com");
              }}
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "14px",
                width: "152px",
              }}>
              <CopyIcon
                className={styles.copyIcon}
                alt=""
                style={{ marginRight: "10px" }}
              />
              Copiar correo
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={dialogPretoken} onClose={() => setDialogPretoken(false)}>
        <div className={styles.dialogPretoken}>
          <div className={styles.titleDescription}>
            <EmailIcon className={styles.emailIcon} alt="" />
            <div className={styles.headline}>Revisa tu correo</div>
            <div className={styles.supportingText}>
              Te enviamos un código a tu correo para verificar tu cuenta.
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              onClick={() => {
                setDialogPretoken(false);
              }}
              id="#token2fa_close"
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "14px",
                width: "152px",
              }}>
              Cerrar
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Login;
