import React, { useContext, useCallback } from "react";
import Draggable from "react-draggable";
import FlowgraphContext from "../FlowgraphContext";
import { useSelector } from "react-redux";

const typeToColorMap = {
  intent: "2px solid #DFBD14",
  condition: "2px solid #A100FF",
  date: "2px solid #9323B3",
  entity: "2px solid #10C600",
  direct: "2px solid #BF0ABD",
};

export default function DraggableEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  data,
  markerEnd,
}) {
  const theme = useSelector((state) => state.theme);

  const flowgraphFunctions = useContext(FlowgraphContext);

  const onDrag = useCallback(
    (edgeUpdate, e) => {
      flowgraphFunctions.handleDrag(edgeUpdate, e);
    },
    [flowgraphFunctions],
  );

  const colorByType = useCallback((data) => {
    return typeToColorMap[data.type] || "2px solid #DFBD14";
  }, []);

  const getHeightForFixedWidth = useCallback(
    (txt, fontname, fontsize, fixedWidth) => {
      const lineHeight = 25;
      // Create a dummy canvas (render invisible with css)
      let c = document.createElement("canvas");
      // Get the context of the dummy canvas
      let ctx = c.getContext("2d");
      // Set the context.font to the font that you are using
      ctx.font = fontsize + "px" + fontname;
      // Measure the string
      let length = ctx.measureText(txt).width + 30;

      const heightMultiplier = Math.ceil(length / fixedWidth);

      return lineHeight * heightMultiplier * 1.4;
    },
    [],
  );

  return (
    <>
      <path
        id={id}
        style={{
          ...style,
          strokeLinejoin: "round",
        }}
        className="react-flow__edge-path"
        d={`
          M${sourceX} ${sourceY}
          l0 ${(data.labelY - sourceY) / 2}
          l${data.labelX - sourceX} 0
          l0 ${(data.labelY - sourceY) / 2}
          M${data.labelX} ${data.labelY}
          l0 ${(targetY - data.labelY) / 2}
          l${targetX - data.labelX} 0
          l0 ${(targetY - data.labelY) / 2}
          `}
        markerEnd={markerEnd}
      />
      <Draggable onDrag={(e) => onDrag(id, e)}>
        <foreignObject
          x={data.labelX - 60}
          y={data.labelY - 20}
          width="120"
          height={getHeightForFixedWidth(
            data.label,
            "Roboto, Helvetica, Arial",
            "12px",
            100,
          )}
          style={{
            transform: "none !important",
            cursor: "grab",
          }}>
          <div
            style={{
              backgroundColor:
                theme.darkTheme === true
                  ? "rgb(255, 255, 255)"
                  : "rgb(32, 33, 46)",
              border: colorByType(data),
              fontSize: "12px",
              borderRadius: "3px",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              KhtmlUserSelect: "none",
              MozUserSelect: "none",
            }}
            className="label-box">
            <span>{data.label}</span>
          </div>
        </foreignObject>
      </Draggable>
    </>
  );
}
