import React, { useState, useEffect, useMemo } from "react";
import { get, post, put, remove } from "../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Button } from "@mui/material";

import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import EditModal from "./EditModal.component";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { DeleteRowModal } from "../shared/helper/tableHelper";

import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";

const ChattigoTable = () => {
  const { t, i18n } = useTranslation("chattigo");
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [clientList, setClientList] = useState([]);
  const theme = useSelector((state) => state.theme);
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "chattigoTable" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };
  const columns = useMemo(
    () => [
      {
        header: t("inboundName"),
        accessorKey: "inboundName",
        size: 300,
      },
      {
        header: t("transferName"),
        accessorKey: "transferName",
        size: 300,
        filterVariant: "multi-select",
        filterSelectOptions: clientList.map((x) => x.name),
        filterFn: (rows, id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(rows.original.transferName);
        },
        //change render value to displayName
        Cell: ({ cell }) => {
          const client = clientList.find((x) => x.name === cell.getValue());
          return client ? client.display_name : cell.getValue();
        },
      },
    ],
    [clientList, i18n.language],
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    getChattigoTable();
    getClientList();
    getUserPermisions();
  }, []);

  const getChattigoTable = () => {
    get("/chattigoTable")
      .then((data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingRoute"), {
          variant: "error",
        });
      });
  };

  const getClientList = () => {
    get("/clients/names")
      .then((data) => {
        setClientList(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingRoute"), {
          variant: "error",
        });
      });
  };

  const newChattigoTable = (row) => {
    const body = {
      inboundName: row.inboundName,
      transferName: row.transferName,
    };
    post("/chattigoTable", body)
      .then(() => {
        enqueueSnackbar(t("newSaved"), {
          variant: "success",
        });
        getChattigoTable();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("error"), {
          variant: "error",
        });
      });
  };

  const updateChattigoTable = (row, values) => {
    const body = {
      _id: row.original._id,
      inboundName: values.inboundName,
      transferName: values.transferName,
    };
    put("/chattigoTable", body)
      .then(() => {
        enqueueSnackbar(t("updated"), {
          variant: "success",
        });
        getChattigoTable();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("error"), {
          variant: "error",
        });
      });
  };

  const deleteChattigoTable = (row) => {
    const body = {
      _id: row.original._id,
    };
    remove("/chattigoTable", body)
      .then(() => {
        enqueueSnackbar(t("deleted"), {
          variant: "success",
        });
        getChattigoTable();
        setDeleteModalOpen(false);
        seteditRow(null);
      })
      .catch(() => {
        enqueueSnackbar(t("error"), {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <div>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("chattigoTable")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "left",
                },
                size: 50,
              },
            }}
            positionActionsColumn="last"
            columns={columns}
            data={data}
            enableColumnOrdering
            enableGlobalFilter={false}
            editDisplayMode="modal"
            enableEditing
            onEditingRowSave={updateChattigoTable}
            enableDelete
            renderRowActions={({ row }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    userPermissions ? t("editButton") : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      onClick={() => {
                        setEditModalOpen(true);
                        seteditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    userPermissions
                      ? t("deleteButton")
                      : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      sx={{ color: "deleteIcon.color" }}
                      onClick={() => {
                        setDeleteModalOpen(true);
                        seteditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Tooltip
                arrow
                placement="right"
                title={userPermissions ? "" : t("noPermissionTooltip")}>
                <span>
                  <Button
                    className="mrt-create-new-account-button"
                    id="mrt-create-new-account-button"
                    onClick={() => {
                      setEditModalOpen(true);
                      setTypeModal("new");
                    }}
                    disabled={!userPermissions}
                    variant="contained">
                    {t("addButton")}
                  </Button>
                </span>
              </Tooltip>
            )}
          />
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteChattigoTable(editRow);
        }}
        title={t("tittleDeleteModal", {
          inboundName: editRow?.original?.inboundName,
          transferName: clientList.find(
            (x) => x.name === editRow?.original?.transferName,
          )?.display_name,
        })}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setTypeModal(null);
        }}
        data={{
          inboundName: editRow?.original?.inboundName,
          transferName: editRow?.original?.transferName,
        }}
        onSave={(values) => {
          if (typeModal === "new") {
            newChattigoTable(values);
            setEditModalOpen(false);
          } else {
            updateChattigoTable(editRow, values);
            setEditModalOpen(false);
          }
        }}
        typeModal={typeModal}
        clientList={clientList}
      />
    </React.Fragment>
  );
};

export default ChattigoTable;
