import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { findModel } from "../shared/helper/findModelsHelper";
import { llmModelsNames, llmServicesNames, embeddingsModelNames} from "../shared/constants/modelsNames";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import ChipInput from "../shared/components/ChipInput";

const EditModal = ({ typeModal, data, configs, open, onClose, onSave }) => {
  const [name, setName] = useState("");
  const { t } = useTranslation("config");
  const [types] = useState([
    {
      text: t("bool"),
      value: "bool",
    },
    {
      text: t("number"),
      value: "int",
    },
    {
      text: t("string"),
      value: "string",
    },
    {
      text: t("stringArray"),
      value: "sarray",
    },
  ]);
  const [type, setType] = useState("");
  const [model, setModel] = useState("");
  const [dirty, setDirty] = useState({});
  const [value, setValue] = useState("");
  const [valueArray, setValueArray] = useState("");
  const valueType = useMemo(() => {
    if (!type) {
      return null;
    }
    return types.find((x) => x.value === type);
  }, [types, type]);
  const embeddingModelValue = useMemo(() => findModel(embeddingsModelNames, model), [model]);
  const llmModelValue = useMemo(() => findModel(llmModelsNames, model), [model]);
  const serviceValue = useMemo(() => findModel(llmServicesNames, model), [model]);
  const [errors, setErrors] = useState({});
  const getOptionTypeLabel = useMemo(() => (option) => option.text, []);

  useEffect(() => {
    if (typeModal !== "new") {
      setName(data.name);
      setType(data.type);
      if (data.type === "sarray") {
        setValueArray(data.value);
      } else {
        setValue(data.value);
        setModel(data.value);
      }

      setDirty({ name: false, type: false, value: false, valueArray: false });
    } else {
      setName("");
      setType("");
      setValue("");
      setValueArray("");
      setDirty({});
    }
  }, [data, typeModal]);

  const validate = useCallback(() => {
    const temp = {};
    temp.name = name !== "" ? "" : t("modalErrorNameRequired");
    let indx = configs.findIndex((item) => {
      return item.name === name;
    });
    if (typeModal === "new" && temp.name === "" && indx !== -1) {
      temp.name = t("modalErrorNameRepited");
    }
    temp.type = type !== "" ? "" : t("modalErrorTypeRequired");
    switch (type) {
      case "sarray":
        temp.valueArray =
          valueArray.length !== 0 ? "" : t("modalErrorValueRequired");
        temp.value = "";
        break;
      case "int":
        temp.valueArray = "";
        temp.value = value === "" ? t("modalErrorValueRequired") : isNaN(value) ? t("modalErrorValueHasToBeNumber") : value < 0 ? t("modalErrorValueNegative") : "";
        break;
      case "bool":
        temp.valueArray = "";
        temp.value = value === "" ? t("modalErrorValueRequired") : (value !== "false" && value !== "true") ? t("modalErrorValueHasToBoolean") : "" ;
        break;
      default:
        temp.valueArray = "";
        temp.value = value === "" ? t("modalErrorValueRequired") : "";
        break;
    }

    if (name === "self_service_agents_limit" && temp.value === "") {
      const maxAgents = data.client.maxAgents;
      temp.value = value < maxAgents ? t("modalErrorMaxAgent") + maxAgents: "";
    }
    if (name === "self_service_nodes_limit" && temp.value === "") {
      const maxNodes = data.client.maxNodes;
      temp.value = value < maxNodes ? t("modalErrorMaxNodes") + maxNodes : "";
    }
    if (!dirty.name && name !== "") {
      setDirty({ ...dirty, name: true });
    }
    if (!dirty.type && type !== "") {
      setDirty({ ...dirty, type: true });
    }
    if (!dirty.value && value !== "") {
      setDirty({ ...dirty, value: true });
    }
    if (!dirty.valueArray && valueArray !== "") {
      setDirty({ ...dirty, valueArray: true });
    }

    if (type) {
      if (types.find((x) => x.value === type) === undefined) {
        temp.transferName = t("modalErrorTransferNotExist");
      }
    }
    setErrors({ ...temp });
  }, [name, type, t, value, valueArray]);

  useEffect(() => {
    validate();
  }, [name, type, value, validate]);

  const handleTypeChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        setType(newValue.value);
      } else {
        setType("");
      }
    },
    [setType],
  );

  const handleModelChange = useCallback(
    (event, newValue) => {
      setValue(newValue ? newValue.id : "");
    },
    [setValue],
  );

  const renderInputTypes = useMemo(() => function renderInput(params){
    return (
      <TextField
        variant="standard"
        {...params}
        label={t("type") + "*"}
        error={errors.type && dirty.type}
        helperText={errors.type && dirty.type ? errors.type : ""}
      />
    );
  }, [t, errors.type, dirty.type]);

  const renderModelTypes = useMemo(() => function renderModel (params) {
    return(
      <TextField
        variant="standard"
        {...params}
        label={t("model") + "*"}
        error={errors.type && dirty.type}
        helperText={errors.type && dirty.type ? errors.type : ""}
      />
    );
  }, [t, errors.type, dirty.type]);

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const handleSave = useCallback(() => {
    onSave({ name, type, value, valueArray });
  }, [name, type, value, valueArray, onSave]);

  const RowEditComponent = () => {
    if (type === "sarray") {
      return (
        <ChipInput
          sx={{ width: "75%" }}
          name="value"
          source="value"
          label={t("value") + "*"}
          value={valueArray}
          updateProps={(e) => setValueArray(e)}
          fullWidthInput
          InputProps={{
            multiline: true,
            id: "chip-value",
            minRows: 1,
            maxRows: 99,
          }}
        />
      );
    }
    if (name === "external_ai_model") {
      return (
        <Autocomplete
          variant="standard"
          options={llmServicesNames}
          getOptionLabel={(option) => option.name}
          value={serviceValue}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleModelChange}
          renderInput={renderModelTypes}
        />
      );
    } else if (name === "embedding_model") {
      return (
        <Autocomplete
          variant="standard"
          options={embeddingsModelNames}
          getOptionLabel={(option) => option.name}
          value={embeddingModelValue}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleModelChange}
          renderInput={renderModelTypes}
        />
      );
    } else if (name === "model_chatGPT") {
      return (
        <Autocomplete
          variant="standard"
          options={llmModelsNames}
          getOptionLabel={(option) => option.name}
          value={llmModelValue}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleModelChange}
          renderInput={renderModelTypes}
        />
      ); 
    } else {
      return (
        <TextField
          id="modal-value"
          variant="standard"
          label={t("value") + "*"}
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={value}
          error={errors.value && dirty.value}
          helperText={errors.value && dirty.value ? errors.value : ""}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {typeModal === "new" ? t("modalTitleNew") : t("modalTitleEdit")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          id="modal-name"
          variant="standard"
          label={t("name") + "*"}
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={name}
          disabled={typeModal !== "new"}
          error={errors.name && dirty.name}
          helperText={errors.name && dirty.name ? errors.name : ""}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <Autocomplete
          id="modal-type"
          variant="standard"
          options={types}
          disabled={typeModal !== "new" ? true : false}
          getOptionLabel={getOptionTypeLabel}
          value={valueType}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleTypeChange}
          renderInput={renderInputTypes}
        />
        {RowEditComponent()}
      </Box>
      <DialogActions>
        <Button id="modal-cancel-button" onClick={onClose}>
          {t("modalCancel")}
        </Button>
        <Button
          id="modal-save-button"
          variant="contained"
          color="primary"
          disabled={hasErrors}
          onClick={handleSave}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
