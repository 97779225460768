import React, { useEffect } from "react";
import TextMessage from "./TextMessage";
import EmojiMessage from "./EmojiMessage";
import FileMessage from "./FileMessage";
import QuickMessage from "./QuickMessage";
import AnimatedMessage from "./AnimatedMessage";
import VideoMessage from "./VideoMessage";
import { Box } from "@mui/material";

const Message = (props) => {
  useEffect(() => {
    let message = props.message;
    message.type = "message";
  }, [props.message.message, props.message.type]);

  const renderMessageOfType = (type) => {
    switch (type) {
      case "message":
        return <TextMessage {...props.message} />;
      case "emoji":
        return <EmojiMessage {...props.message} />;
      case "file":
        return <FileMessage {...props.message} />;
      case "quick":
        return (
          <Box>
            <QuickMessage {...props} />
          </Box>
        );
      case "animated":
        return <AnimatedMessage {...props} />;
      case "video":
        return <VideoMessage {...props} />;
      default:
        console.error(
          `Attempting to load message with unsupported file type '${type}'`,
        );
    }
  };

  let contentClassList = [
    "sc-message--content",
    props.message.user === "system" ? "sent" : "received",
  ];

  return (
    <div className="sc-message" style={{ justifyContent: "center" }}>
      <div
        className={contentClassList.join(" ")}
        style={{
          minWidth: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: props.message.user === "system" ? "flex-end" : undefined,
        }}>
        {renderMessageOfType(props.message.type)}
        {props.message.user === "system" ? (
          <span style={{ fontSize: "0.7em", marginRight: "10px" }}>System</span>
        ) : (
          <span style={{ fontSize: "0.7em", marginLeft: "10px" }}>
            {props.message.user}
          </span>
        )}
      </div>
    </div>
  );
};

export default Message;
