import React, { useEffect, useState } from "react";
import {
  Dialog,
  TextField,
  Button,
  Autocomplete,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import styles from "../Home.module.css";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ChipInput from "../../shared/components/ChipInput";
import { useSnackbar } from "notistack";
import { post } from "../../shared/http/httpService";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { orderAlphabeticallyWithAttribute } from "../../shared/helper/orderAlphabetically";
import { get } from "../../shared/http/httpService";
import { useNavigate } from "react-router-dom";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-clearIndicator": {
    display: "none",
  },
  "& .MuiAutocomplete-endAdornment button": {
    backgroundColor: "inherit",
  },
  "& .MuiAutocomplete-endAdornment button:hover": {
    backgroundColor: "inherit",
    boxShadow: "none",
  },
  "& .MuiAutocomplete-endAdornment svg": {
    color: "#111",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "rgba(213, 213, 231, 0.2)",
    color: "rgba(213, 213, 231, 0.2)",
    padding: "13px",
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderBottomColor: "#7e7e7e", // Default state
      borderRadius: "0px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderBottomColor: "#7e7e7e", // Hover state
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderBottomColor: "#7e7e7e", // Focused state
    },
  },
});

const NewScenario = ({ open, handleClose }) => {
  const [scenarioName, setScenarioName] = useState("");
  const [scenarioDescription, setScenarioDescription] = useState("");
  const [knowledge, setKnowledge] = useState([]);
  const { t } = useTranslation("newScenario");
  const { enqueueSnackbar } = useSnackbar();
  const [agentNames, setAgentNames] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    if (open) {
      getAgents();
    }
  }, [open]);

  const newScenario = () => {
    const body = {
      name: scenarioName,
      display_name: scenarioName,
      description: scenarioDescription,
      knowledge: knowledge,
      //self_service: config.self_service,
    };
    post("/agents", body)
      .then(() => {
        enqueueSnackbar(t("newScenario"), {
          variant: "success",
        });
        navigate("/flowgraph", { state: { scenarioName: scenarioName } });
      })
      .catch(() => {
        enqueueSnackbar(t("errorNewScenario"), {
          variant: "error",
        });
      });
    handleClose();
  };

  const getAgents = () => {
    get("/agents")
      .then(async (data) => {
        let newData = orderAlphabeticallyWithAttribute(data.agents, "name");
        let agentNames = newData.map((agent) => agent.name);
        setAgentNames(agentNames);
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorGettingAgents"), {
          variant: "error",
        });
      });
  };

  return (
    <Dialog
      PaperProps={{
        className: styles.paper, // Usa la clase definida en tu CSS
      }}
      maxWidth={false}
      open={open}
      onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "13",
        }}>
        <AddIcon fontSize="large" style={{ color: "#1a1a1a" }}></AddIcon>
        <div>
          <span className={styles.descriptionScenario}>
            {t("headerDescripction")}
          </span>
          <span className={styles.headerTitle}>{t("headerTitle")}</span>
        </div>
      </DialogTitle>{" "}
      <div className={styles.scenarioDivider}></div>
      <DialogContent>
        <span className={styles.descriptionScenario}>
          {t("contentWelcomeMessage")}
        </span>
        <span className={styles.titleNewScenario}>{t("scenarioName")}</span>
        <TextField
          className={styles.scenarioTextfield}
          label={t("scenarioNameLabel")}
          fullWidth
          margin="normal"
          variant="filled"
          value={scenarioName}
          onChange={(e) => setScenarioName(e.target.value)}
        />
        <span className={styles.titleNewScenario}>
          {t("scenarioDescription")}
        </span>
        <TextField
          className={styles.scenarioTextfield}
          label="scenarioDescriptionLabel"
          fullWidth
          margin="normal"
          variant="filled"
          multiline
          rows={4}
          value={scenarioDescription}
          onChange={(e) => setScenarioDescription(e.target.value)}
        />
        <div
          className={styles.titleNewScenario}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span className={styles.titleScenario}>{t("scenarioKnowledge")}</span>
          <Tooltip
            title={t("scenarioKnowledgeTooltip")}
            arrow
            placement="right">
            <InfoOutlinedIcon style={{ color: "#111" }} />
          </Tooltip>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
          className={styles.knowledge}>
          <StyledAutocomplete
            multiple
            inputValue=""
            style={{ height: "100%" }}
            options={agentNames ? agentNames : []}
            value={knowledge ? knowledge : []}
            getOptionLabel={(option) => option}
            renderTags={() => null}
            disableCloseOnSelect
            fullWidth
            blurOnSelect
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  ...props.style,
                  backgroundColor: selected ? "#444" : "transparent", // Cambia '#f0f0f0' por el color deseado
                }}>
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t("scenarioKnowledgeLabel")}
                placeholder="Knowledge"
              />
            )}
            onInputChange={() => {}}
            onChange={(e, value) => setKnowledge(value)}
            sx={{
              width: "35%",
              marginRight: "1rem",
            }}
          />
          <span className={styles.chip} style={{ width: "100%" }}>
            <ChipInput
              fontColor="#1a1a1a"
              name="sensitive-params"
              source="params"
              updateProps={(knowledge) => {
                setKnowledge(knowledge);
              }}
              //disabled={!userPermissions}
              value={knowledge ? knowledge : []}
            />
          </span>
        </div>
      </DialogContent>
      <div className={styles.scenarioDivider}></div>
      <DialogActions
        style={{ flexDirection: "column", alignItems: "flex-start" }}>
        <div>
          <span className={styles.helperMessage}>
            {t("contentWelcomeMessage")}
          </span>
        </div>
        <div
          className={styles.dialogActions}
          style={{ alignSelf: "end", marginTop: "20px" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: "#2C2F88",
              marginRight: "8px", // Asegura un espacio entre los botones
            }}>
            {t("cancelButton")}
          </Button>
          <Button onClick={newScenario} variant="contained" color="primary">
            {t("saveButton")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default NewScenario;
