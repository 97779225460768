import React, { Fragment, useEffect, useState } from "react";
import {Box, Grid} from "@mui/material";
import RouteItem from "./RouteItem";


const ApiRoutes = ({routes, host, modalDeleteRoute, saveRoute}) => {
  const [arrayRoutes, setArrayRoutes] = useState([]);


  useEffect(() => {
    if (routes?.length>=0){
      const initialRoutes = routes.map((route) => ({
        ...route,
        editing: route.editing !== undefined ? route.editing : false,
      }));
      setArrayRoutes(initialRoutes);
    } else {
      setArrayRoutes([]);
    }
  }, [routes]);


  return (
    <Fragment>
      <Grid item columns={3} sx={{ display: "flex", flexDirection: "column", alignItems:"flex-start" }} >
        {
          (arrayRoutes.length>0) ? arrayRoutes.map ((route, index) => 
          {
            return (
              <Box key={index} xl={2} sx={{padding: "16px", marginTop: "24px", borderRadius: "5px", width: "35vw", border: "1px solid #4e8cff" }}>
                <RouteItem route={{...route, host:host}} arrayRoutes={arrayRoutes} _id={index} modalDeleteRoute={modalDeleteRoute} saveRoute={saveRoute} />
              </Box>
            );
          }) : null
        }
      </Grid>
    </Fragment>
  );
};

export default ApiRoutes;