import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setTheme } from "../shared/redux/themeSlice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardMedia, CardActionArea } from "@mui/material";
import scenarioImage from "./media/scenario.png";
import documentationImage from "./media/documentation.png";
import { get } from "../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import NewScenario from "./Scenarios/NewScenario";
import styles from "./Home.module.css";
import FabButtons from "./FabButtons/FabButtons";
import FabNewScenarioButton from "./FabButtons/FabNewScenarioButton";

const Home = ({ token }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const client = useSelector((state) => state.client);
  const [openNewScenario, setOpenNewScenario] = useState(false);
  const { t } = useTranslation("home");
  const { enqueueSnackbar } = useSnackbar();
  const [scenarios, setScenarios] = useState([]);
  let navigate = useNavigate();
  //let auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (token && client.name) {
      let scenariosToGet = updateVisibleScenarios();
      getLatestScenarios(scenariosToGet);
    }
  }, [token, client.name]);

  useEffect(() => {
    const storedTheme =
      localStorage.getItem("theme") === "false" ? false : true;

    if (theme !== storedTheme) {
      dispatch(setTheme(storedTheme));
    }
  }, [theme, dispatch]);

  const getLatestScenarios = (scenariosToGet) => {
    scenariosToGet = scenariosToGet === 0 ? 1 : scenariosToGet;
    get("/flows/latest?scenariosToGet=" + scenariosToGet)
      .then(async (data) => {
        setScenarios(data);
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorGettingScenarios "), {
          variant: "error",
        });
      });
  };

  const updateVisibleScenarios = () => {
    const containerWidth = window.innerWidth; // Ancho de la ventana
    const cardWidth = 420; // Ancho fijo de cada tarjeta
    const gapWidth = 16; // Ancho del gap entre tarjetas
    const maxCards = Math.floor(
      (containerWidth + gapWidth) / (cardWidth + gapWidth),
    ); // Número máximo de tarjetas que caben
    return maxCards;
  };

  // Función para determinar el mensaje a mostrar
  const getTimeMessage = (updatedAt) => {
    const updatedAtDate = new Date(updatedAt);
    const now = new Date();
    const differenceInHours = (now - updatedAtDate) / (1000 * 60 * 60);

    if (differenceInHours < 0.1) {
      // Menos de 6 minutos, aproximadamente "un momento"
      return t("updatedJustNow");
    } else if (differenceInHours < 4) {
      // Menos de 4 horas
      return t("updatedLessThanFourHours");
    } else {
      // Más antiguo
      return t("updatedOlder");
    }
  };

  return (
    <div className={styles.container} id="home">
      <FabButtons></FabButtons>
      <NewScenario
        open={openNewScenario}
        handleClose={() => setOpenNewScenario(false)}
      />
      <div className={styles.homeBackground}>
        <div className={styles.header}>
          <span className={styles.headline}>{t("recentScenariosTitle")}</span>
          <FabNewScenarioButton
            setOpenNewScenario={setOpenNewScenario}></FabNewScenarioButton>
        </div>

        <div className={styles.scenarios}>
          {scenarios?.map((scenario, index) => (
            <Card
              className={styles.scenario}
              variant="elevation"
              elevation={2}
              key={index}>
              <CardActionArea
                key={index}
                className={styles.scenario}
                onClick={() =>
                  navigate("/flowgraph", {
                    state: { scenarioName: scenario.agent.name },
                  })
                }>
                <div className={styles.scenario}>
                  <CardMedia
                    component="img"
                    height="210"
                    image={scenarioImage}
                    title={scenario.agent?.name}
                  />
                  <CardContent className={styles.cardContent}>
                    <span className={styles.titleScenario}>
                      {scenario.agent?.name}
                    </span>
                    <br />
                    <span className={styles.descriptionScenario}>
                      {scenario.agent?.description || "\u00A0"}
                    </span>
                    <span className={styles.lastUpdateScenario}>
                      {getTimeMessage(scenario.updatedAt)}
                    </span>
                  </CardContent>
                </div>
              </CardActionArea>
            </Card>
          ))}
        </div>
        <span className={styles.headline}>{t("learnNowTitle")}</span>
        <div className={styles.divider}></div>
        <div className={styles.scenarios}>
          <Card
            className={styles.documentationCard}
            variant="elevation"
            elevation={2}>
            <a
              style={{ margin: 0, display: "inline-flex" }}
              href="https://evoltis.atlassian.net/wiki/spaces/DAR/overview"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.documentationCard}>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="220"
                image={documentationImage}
                title="Contemplative Reptile"></CardMedia>
              <CardContent className={styles.cardContent}>
                <span className={styles.titleScenario}>
                  {t("userManualTitle")}
                </span>
                <br></br>
                <span className={styles.descriptionScenario}>
                  {t("userManualdescription")}
                </span>
              </CardContent>
            </a>
          </Card>
        </div>

        <div className={styles.learnDarwin}></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme.darkTheme,
});
export default connect(mapStateToProps)(Home);
