import { ThemeProvider } from "@emotion/react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import DateRange from "../shared/components/DateRange";
import dayjs from "dayjs";
import Transition from "../shared/helper/transitionDialog";
import { get } from "../shared/http/httpService";

import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { IconButton } from "@mui/material";
import { tableDarkTheme, tableLightTheme } from "../shared/theming/table.theme";

// Material UI
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import ChatViewer from "../NoMatch/Chat/ChatViewer.component";
import { TextField } from "@mui/material";

const Conversations = () => {
  const theme = useSelector((state) => state.theme);
  const { t, i18n } = useTranslation("conversations");
  const [conversation, setConversation] = useState([]);
  const [searchIdChat, setSearchIdChat] = useState("");
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [open, setOpen] = useState(false);
  const [valid, setValid] = useState(true);
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "noMatch" && permission.fullAccess === true,
    );
    console.log(userPermissions);

    setUserPermissions(hasPermissions);
  };

  const columns = useMemo(
    () => [
      {
        header: t("idchat"),
        accessorKey: "idChat",
        size: 150,
      },
      {
        header: t("room"),
        accessorKey: "room",
        size: 150,
      },
      {
        header: t("channel"),
        accessorKey: "channel",
        size: 5,
        Cell: (cell) => {
          if (cell.renderedCellValue === "MESSENGER") {
            return "FACEBOOK";
          }
          if (cell.renderedCellValue === "INSTAGRAMDIRECT") {
            return "INSTAGRAM";
          }
          return cell.renderedCellValue;
        },
      },
      {
        header: t("date"),
        accessorKey: "date",
        filterVariant: "date",
        filterFn: (rows, id, filterValue) => {
          if (!rows.original[id]) {
            return true;
          }
          const rowDate = dayjs(rows.original[id]);
          const filterDate = dayjs(filterValue);
          return rowDate.isSame(filterDate, "day");
        },
        sortingFn: "datetime",
        Cell: ({ cell }) => dayjs(cell.getValue()).format("DD-MM-YYYY"),
      },
    ],
    [i18n.language],
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getUserPermisions();
    setData([]);
  }, []);

  const handleOpen = (row) => {
    let room = row.original.room;
    let idChat = row.original.idChat;
    get("/report/conversationByIdChat", { room, idChat })
      .then(async (data) => {
        data.conversations.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        setConversation(data.conversations);
        setOpen(true);
      })
      .catch(() => {
        enqueueSnackbar(t("messageErrorGettingConversation"), {
          variant: "error",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDate = function (dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
      setValid(dates.valid);
    } else {
      setValid(dates.valid);
    }
  };

  const handleSearchChange = (event) => {
    setSearchIdChat(event.target.value);
  };

  const getConversations = async () => {
    get("/report/conversationReport", {
      startDate: dayjs(startDate).toDate(),
      endDate: dayjs(endDate).toDate(),
      idChat: searchIdChat,
    })
      .then(async (data) => {
        setData(data.table);
      })
      .catch(() => {
        enqueueSnackbar(t("mesaggeErrorGettingNoMatch"), {
          variant: "error",
        });
      });
  };

  const fetch = () => {
    getConversations();
  };

  const localeMap = {
    ES: "es",
    EN: "en",
  };

  const table = useMaterialReactTable({
    columns,
    data,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
      },
    },
    positionActionsColumn: "last",
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    defaultColumn: {
      minSize: 20,
      maxSize: 100,
      size: 80,
    },
    enableDelete: true,
    editDisplayMode: "modal",
    enableEditing: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title={t("tooltipViewConversation")}>
          <IconButton
            id="view-conversation-icon"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpen(row)}>
            <LibraryBooksIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1,
          ml: 1,
          bgcolor: "boxAnalytics.background",
        }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <TextField
              label={t("searchByIdChat")}
              value={searchIdChat}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item>
            <DateRange
              startDate={dayjs(startDate)}
              endDate={dayjs(endDate)}
              onDateSelected={handleDate}
              language={i18n.language}
              maxOneWeek={true}
            />
          </Grid>
          <Grid item>
            <Button
              id="fetch-button"
              className={"WithSnackbar(NoMatch)-button-2"}
              variant="contained"
              size="medium"
              onClick={fetch}
              disabled={!valid}
              sx={{
                minWidth: "130px",
                height: "40px",
              }}>
              {t("fetch")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    ),
  });

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={localeMap[i18n.language]}>
        <div>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {t("conversations")}
          </Typography>
          <ThemeProvider
            theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
            <MaterialReactTable table={table} />
          </ThemeProvider>
        </div>
        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth>
          <DialogTitle>{t("conversation")}</DialogTitle>
          <DialogContent>
            <ChatViewer conversation={conversation}></ChatViewer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("close")}</Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default Conversations;
