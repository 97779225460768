import React, { useEffect, useState } from "react";
import { Box, FormControl, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { put, get } from "../../../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

const LanguageBox = () => {
  let auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [languages, setLanguages] = useState([]);

  const obtainLanguages = () => {
    get("/languages").then(async (data) => {
      setLanguages(data);
    });
  };

  useEffect(() => {
    obtainLanguages();
  }, []);

  const handleLangChange = (language) => {
    const selectedValue = language;
    const user = {
      _id: auth._id,
      language: selectedValue,
    };

    put("/users/preferences", user)
      .then((response) => {
        if (response) {
          i18next.changeLanguage(language);
          localStorage.setItem("language", selectedValue);
          enqueueSnackbar(t("preferencesLanguage:languageChanged"), {
            variant: "success",
          });
        }
      })
      .catch(() =>
        enqueueSnackbar(t("preferencesLanguage:errorChanginLanguage"), {
          variant: "error",
        }),
      );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography
        id="language-title"
        marginLeft="20px"
        align="left"
        variant="h5">
        {t("preferencesLanguage:language")}
      </Typography>

      <div
        style={{
          margin: "45px",
          textAlign: "left",
        }}>
        <FormControl>
          {languages.map((language) => (
            <Button
              id={`${language.language}-button`}
              key={language.language}
              align="left"
              variant="contained"
              size="medium"
              sx={{
                marginBottom: "10px",
                marginTop: "10px",
                backgroundColor:
                  i18next.language === language.language
                    ? "primary"
                    : "disabledButton.backgroundColor",
              }}
              onClick={() => handleLangChange(language.language)}>
              {t(`preferencesLanguage:${language.buttonText}`)}
            </Button>
          ))}
        </FormControl>
      </div>
    </Box>
  );
};

const TabLanguage = (props) => {
  const { index, value } = props;
  return (
    <div
      role="tabLanguage"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ height: "50vh", width: "50%" }}>
      <LanguageBox />
    </div>
  );
};

export { TabLanguage };
