import { React, useState, useEffect } from "react";
import { Box, Tab, Dialog } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EdgeModal from "./EdgeModal.component";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function a11yProps(index) {
  return {
    id: `edgeModal-tab-${index}`,
    "aria-controls": `edgeModal-tabpanel-${index}`,
  };
}

export default function EdgeModalTabsPanel({
  open,
  onClose,
  editEdgeProps,
  previous,
  onSave,
  onDelete,
  intents,
  edges,
  pages,
  userPermissions,
  propsSelectedTab,
  onDeleteTab,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [conditions, setConditions] = useState([]);
  const [editEdge, setEditEdge] = useState({ data: {} });

  useEffect(() => {
    if (Object.keys(editEdgeProps.data).length !== 0) {
      let tempSelectedTab = propsSelectedTab ? propsSelectedTab : 0;
      if (Object.keys(editEdge.data).length !== 0 && editEdgeProps.id == editEdge.id) {
        setEditEdgeData(editEdgeProps, tempSelectedTab);
      } else {
        setEditEdge(editEdgeProps);
      }
      setConditions(editEdgeProps.data.conditionsData);
      setSelectedTab(tempSelectedTab);
    }
  }, [editEdgeProps]);

  const setEditEdgeData = (tempEditEdge, index) => {
    let newEditEdge = { ...editEdge };
    newEditEdge.is_new = tempEditEdge.is_new;
    //if its a new edge from the add button, copy the last item
    if (!tempEditEdge.data.conditionsData[index]) {
      let lastItem = { ...tempEditEdge.data.conditionsData.slice(-1)[0] };
      lastItem.label = "New Transition";
      tempEditEdge.data.conditionsData.push(lastItem);
    }

    newEditEdge.data.conditions =
      tempEditEdge.data.conditionsData[index].conditions;
    newEditEdge.data.conditionsData = tempEditEdge.data.conditionsData;
    newEditEdge.data.label = tempEditEdge.data.conditionsData[index].label;
    newEditEdge.data.type = tempEditEdge.data.conditionsData[index].type;
    newEditEdge.data.intents = tempEditEdge.data.conditionsData[index]?.intents;
    newEditEdge.data.presets = tempEditEdge.data.conditionsData[index]?.presets;
    newEditEdge.data.cleanParams =
      tempEditEdge.data.conditionsData[index]?.cleanParams;
    setEditEdge(newEditEdge);
  };

  const handleChange = (event, newselectedTab) => {
    setSelectedTab(newselectedTab);
    setEditEdgeData(editEdge, newselectedTab);
  };

  const handleClose = () => {
    setEditEdge({ data: {} });
    onClose();
  };

  const handleDeleteTransition = (index) => {
    if (editEdge.data.conditionsData.length !== 1) {
      let tempEditEdge = { ...editEdge };
      tempEditEdge.data.conditionsData.splice(index, 1);
      let newIndex =
        index === tempEditEdge.data.conditionsData.length ? index - 1 : index;
      setEditEdgeData(tempEditEdge, newIndex);
      setConditions(tempEditEdge.data.conditionsData);
      setSelectedTab(newIndex);
      onDeleteTab(tempEditEdge);
    } else {
      onDelete(editEdge);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "900px",
          position: "absolute",
          top: 30,
          height: "auto",
          overflow: "auto",
        },
      }}>
      <Box>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              scrollButtons="auto"
              variant="scrollable">
              {conditions?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={index}
                    label={conditions[index].label}
                    {...a11yProps(index)}></Tab>
                );
              })}
              <Tab
                key={conditions.length}
                value={conditions.length}
                icon={<AddIcon />}></Tab>
            </TabList>
          </Box>
          {conditions?.map((item, index) => {
            return (
              <TabPanel key={index} value={index} sx={{ padding: 0 }}>
                <div
                  style={{ display: index === selectedTab ? "block" : "none" }}>
                  <EdgeModal
                    onClose={handleClose}
                    editEdge={editEdge}
                    previous={previous}
                    onSave={onSave}
                    onDelete={handleDeleteTransition}
                    intents={intents}
                    edges={edges}
                    pages={pages}
                    transitions={edges}
                    userPermissions={userPermissions}
                    index={index}
                    selectedTab={selectedTab}
                  />
                </div>
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </Dialog>
  );
}
