import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, IconButton, Collapse, Checkbox } from "@mui/material";
import { Card, CardContent, CardHeader } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChipInput from "../../shared/components/ChipInput";
import { useTranslation } from "react-i18next";

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const keysNotShow = [
  "_id",
  "__v",
  "agent",
  "response",
  "name",
  "lang",
  "tags",
  "conditions",
  "quickReplies",
  "quickRepliesDynamic",
];
const keysChip = ["phrases", "text", "responseAlt"];
const keysBoolean = [
  "oneWord",
  "excludeRankings",
  "excludeAlternatives",
  "responseImagePosition",
];
const colors = {
  create: "tracesType.create",
  update: "tracesType.update",
  delete: "tracesType.delete",
};

const order = [
  "phrases",
  "text",
  "destinationPage",
  "responseAlt",
  "suggestion_message",
  "oneWord",
  "excludeAlternatives",
  "excludeRankings",
  "tags",
  "quickReplies",
  "quickRepliesDynamic",
  "responseAnimated",
  "responseImageType",
  "responseImagePosition",
  "conditions",
];
const CardTrace = ({ trace, agentPages }) => {
  const [expanded, setExpanded] = useState(false);
  const [date, setDate] = useState("");
  const [color] = useState(colors[trace.type]);
  const { t } = useTranslation();

  const types = {
    create: t("tracesHistory:create"),
    update: t("tracesHistory:update"),
    delete: t("tracesHistory:delete"),
  };

  const translationsKeys = {
    phrases: t("intents:phrases"),
    oneWord: t("intents:oneWord"),
    text: t("intents:answer"),
    responseAnimated: t("intents:responseAnimated"),
    responseImageType: t("intents:imageType"),
    excludeAlternatives: t("intents:excludeAlternatives"),
    excludeRankings: t("intents:excludeIntents"),
    tags: t("intents:tags"),
    conditions: t("intents:dateConditions"),
    quickReplies: t("intents:quickReplies"),
    quickRepliesDynamic: t("intents:quickRepliesDynamic"),
    response: t("intents:response"),
    responseAlt: t("intents:responseAlt"),
    responseImagePosition: t("intents:responseImagePosition"),
    destinationPage: t("intents:destinationPage"),
    suggestion_message: t("intents:suggestionMessage"),
    reverse: t("intents:reverse"),
    time: t("intents:time"),
    date: t("intents:date"),
    day: t("intents:dayName"),
    holiday: t("intents:holiday"),
    arrayName: t("flowgraph:quickRepliesArray"),
    label: t("flowgraph:quickRepliesLabel"),
    value: t("flowgraph:quickRepliesValue"),
    description: t("flowgraph:quickRepliesDescription"),
  };

  useEffect(() => {
    let date = new Date(trace.date);
    date = date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    setDate(date);
  }, [trace]);

  const traceText = (key, value) => {
    if (key === "destinationPage") {
      value = agentPages.find((page) => page.id === value)?.data.label;
    }
    return (
      <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
        {translationsKeys[key]}: {value}
      </Typography>
    );
  };

  const traceDeleteText = (key) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {translationsKeys[key]}:
        </Typography>
        <Typography
          sx={{
            marginLeft: 1,
            fontSize: "14px",
            color: "tracesName.delete",
            fontWeight: "bold",
          }}>
          {t("tracesHistory:deleteField")}
        </Typography>
      </div>
    );
  };

  const traceChip = (key, value) => {
    return (
      <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
        {translationsKeys[key]}: {<ChipInput value={value} disabled />}
      </Typography>
    );
  };

  const traceBoolean = (key, value) => {
    return (
      <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
        {translationsKeys[key]}: {<Checkbox checked={value} />}
      </Typography>
    );
  };

  const traceQuickReplies = (key, value) => {
    return (
      <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
        {value.map((item) => {
          return (
            <Box key={item}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "5px",
                backgroundColor: "background.default",
                maxWidth: "100%",
                flexWrap: "wrap",
                marginTop: 0.5,
              }}>
              <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
                {t("tracesHistory:titleQuickreply")}:{item.label}
              </Typography>
              <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
                {t("tracesHistory:payloadQuickreply")}:{item.value}
              </Typography>
              {item.description && item.description.length > 0 ? (
                <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
                  {t("descriptionQuickreply:payloadQuickreply")}:
                  {item.description}
                </Typography>
              ) : null}
            </Box>
          );
        })}
      </Typography>
    );
  };

  const traceQuickRepliesDynamic = (key, value) => {
    if (value?.length === 0) return null;
    //validate if the array has more than one element and this elements is not only _id
    if (
      value[0] &&
      ((Object.keys(value[0])?.length === 1 &&
        Object.keys(value[0])?.some((key2) => key2 !== "_id")) ||
        Object.keys(value[0])?.length > 1)
    ) {
      return (
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {translationsKeys[key]}:
          {Object.keys(value[0]).map((key2) => {
            if (key2 === "_id") return null;
            return (
              <Typography key={key2} sx={{ marginLeft: 2, fontSize: "14px" }}>
                {translationsKeys[key2]}: {value[0][key2]}
              </Typography>
            );
          })}
        </Typography>
      );
    }
    return null;
  };

  const traceConditions = (key, value) => {
    if (value?.length === 0) return null;
    if (value[0] && Object.keys(value[0]).length > 0) {
      return (
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {translationsKeys[key]}:
          {value.map((item) => {
            return (
              <Box key={item}
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "5px",
                  backgroundColor: "background.default",
                  maxWidth: "100%",
                  marginTop: 0.5,
                }}>
                <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
                  {t("tracesHistory:id")}:{item.id}{" "}
                  <span style={{ marginLeft: "25px" }}></span>
                  {t("tracesHistory:op")}:{translationsKeys[item.op]}
                </Typography>
                {conditionsValue(item.value, item.op)}
                {item.op === "holiday" && item.reverse
                  ? traceBoolean("reverse", item.reverse)
                  : null}
                <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
                  {t("intents:conditionMessage")}:{item.reply}
                </Typography>
              </Box>
            );
          })}
        </Typography>
      );
    }
    return null;
  };

  const conditionsValue = (value, op) => {
    let startTime;
    let endTime;
    let startDate;
    let endDate;
    switch (op) {
      case "time":
        startTime = value.startTime;
        endTime = value.endTime;
        startTime = startTime.slice(0, 5);
        endTime = endTime.slice(0, 5);
        return (
          <div>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {t("tracesHistory:startTime")}: {startTime}
            </Typography>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {t("tracesHistory:endTime")}: {endTime}
            </Typography>
          </div>
        );
      case "date":
        startDate = new Date(value.startDate);
        endDate = new Date(value.endDate);
        return (
          <div>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {t("tracesHistory:startDate")}:{startDate.toLocaleDateString()}
            </Typography>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {t("tracesHistory:endDate")}:{endDate.toLocaleDateString()}
            </Typography>
          </div>
        );
      case "day":
        return (
          <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
            {t("tracesHistory:days")}:
            {value.map((item) => {
              return item ? item + "|" : " |";
            })}
          </Typography>
        );
      case "holiday":
        return (
          <div>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {t("tracesHistory:values")}:
            </Typography>
            {value.map((item) => {
              return (
                <Typography key={item} sx={{ marginLeft: 2, fontSize: "14px" }}>
                  {item}
                </Typography>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  const traceTags = (key, value) => {
    if (value?.length === 0)
      return (
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {translationsKeys[key]}: {t("tracesHistory:deleteTag")}
        </Typography>
      );
    let tagsNames = value.map((item) => {
      return item.name;
    });
    return traceChip(key, tagsNames);
  };

  const displaySubTitle = (key) => {
    return (
      <Typography sx={{ marginTop: 2 }} variant="subtitle1">
        {t(key)}
      </Typography>
    );
  };

  const normalizeName = (name) => {
    if (name.includes("-")) {
      name = name.replace(/-/g, " ");
    }
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  };

  function renderTitle(trace, types) {
    return (
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        {trace.changes.name &&
        trace.changes._id?.name &&
        trace.type === "update" ? (
            <React.Fragment>
              <Typography>{types[trace.type]}</Typography>
              <Typography
                sx={{
                  marginLeft: 0.5,
                  color: "tracesName.delete",
                  textDecoration: "line-through",
                }}>
                {normalizeName(trace.changes._id.name)}
              </Typography>
              <Typography sx={{ marginLeft: 0.5 }}>{"➜"}</Typography>
              <Typography sx={{ color: "tracesName.new", marginLeft: 0.5 }}>
                {normalizeName(trace.changes.name)}
              </Typography>
            </React.Fragment>
          ) : trace.changes._id && trace.type !== "create" ? (
            <Typography>
              {types[trace.type] + normalizeName(trace.changes._id.name)}
            </Typography>
          ) : trace.changes.name && trace.type === "create" ? (
            <React.Fragment>
              <Typography>{types[trace.type]}</Typography>
              <Typography sx={{ color: "tracesName.new", marginLeft: 0.5 }}>
                {normalizeName(trace.changes.name)}
              </Typography>
            </React.Fragment>
          ) : null}
      </div>
    );
  }

  function renderChanges(trace) {
    let sortedChanges = {
      phrases: trace.changes?.phrases,
      text: trace.changes.response?.text,
      destinationPage: trace.changes.destinationPage,
      responseAlt: trace.changes.response?.responseAlt,
      suggestion_message: trace.changes.suggestion_message,
      oneWord: trace.changes.oneWord,
      excludeAlternatives: trace.changes.excludeAlternatives,
      excludeRankings: trace.changes.excludeRankings,
      tags: trace.changes.tags,
      responseAnimated: trace.changes.response?.responseAnimated,
      responseImageType: trace.changes.response?.responseImageType,
      responseImagePosition: trace.changes.response?.responseImagePosition,
      quickReplies: trace.changes.quickReplies,
      quickRepliesName: trace.changes.quickRepliesName,
      conditions: trace.changes.conditions,
    };

    let subtitleResponses = false;
    let subtitleMedia = false;
    let subtitleQR = false;
    let subtitleVarious = false;
    let subtitleAnswerTC = false;
    return order.map((key) => {
      const value = sortedChanges[key];
      if (value !== null && value !== undefined) {
        if (value === "" && trace.type !== "create")
          return traceDeleteText(key);
        if (value?.length === 0 && trace.type !== "create")
          return traceDeleteText(key);
        if (key === "text") {
          return (
            <>
              {keysChip.includes(key) && value?.length > 0
                ? traceChip(key, value)
                : keysBoolean.includes(key)
                  ? traceBoolean(key, value)
                  : value.length > 0
                    ? traceText(key, value)
                    : null}
            </>
          );
        } else if (key === "responseAlt") {
          if (!subtitleResponses) {
            subtitleResponses = true;
            return (
              <>
                {displaySubTitle("tracesHistory:subtitleResponses")}
                {keysChip.includes(key) && value?.length > 0
                  ? traceChip(key, value)
                  : keysBoolean.includes(key)
                    ? traceBoolean(key, value)
                    : value.length > 0
                      ? traceText(key, value)
                      : null}
              </>
            );
          }
        } else if (
          key === "responseAnimated" ||
          key === "responseImageType" ||
          key === "responseImagePosition"
        ) {
          if (!subtitleMedia) {
            subtitleMedia = true;
            return (
              <>
                {displaySubTitle("tracesHistory:subtitleMedia")}
                {keysChip.includes(key) && value?.length > 0
                  ? traceChip(key, value)
                  : keysBoolean.includes(key)
                    ? traceBoolean(key, value)
                    : value.length > 0
                      ? traceText(key, value)
                      : null}
              </>
            );
          } else {
            return (
              <>
                {keysChip.includes(key) && value?.length > 0
                  ? traceChip(key, value)
                  : keysBoolean.includes(key)
                    ? traceBoolean(key, value)
                    : value.length > 0
                      ? traceText(key, value)
                      : null}
              </>
            );
          }
        }
        if (value === "") {
          return traceDeleteText(key);
        } else if (value?.length === 0) {
          return traceDeleteText(key);
        } else if (
          (key === "suggestion_message" || key === "destinationPage") &&
          value?.length > 0
        ) {
          if (!subtitleResponses) {
            subtitleResponses = true;
            return (
              <>
                {displaySubTitle("tracesHistory:subtitleResponses")}
                {traceText(key, value)}
              </>
            );
          } else {
            return traceText(key, value);
          }
        } else if (
          keysChip.includes(key) &&
          value !== undefined &&
          value?.length > 0
        ) {
          return traceChip(key, value);
        } else if (
          (trace.type !== "create" &&
            keysBoolean.includes(key) &&
            sortedChanges[key] !== undefined) ||
          (trace.type === "create" && sortedChanges[key] === true)
        ) {
          if (!subtitleVarious) {
            subtitleVarious = true;
            return (
              <>
                {displaySubTitle("tracesHistory:subtitleVarious")}
                {traceBoolean(key, value)}
              </>
            );
          } else {
            return traceBoolean(key, value);
          }
        } else if (
          key === "quickReplies" &&
          value !== undefined &&
          value?.length > 0
        ) {
          if (!subtitleQR) {
            subtitleQR = true;
            return (
              <>
                {displaySubTitle("tracesHistory:subtitleQR")}
                {traceQuickReplies(key, value)}
              </>
            );
          } else {
            return traceQuickReplies(key, value);
          }
        } else if (
          key === "quickRepliesDynamic" &&
          value !== undefined &&
          value?.length > 0
        ) {
          return traceQuickRepliesDynamic(key, value);
        } else if (
          key === "conditions" &&
          value !== undefined &&
          value?.length > 0
        ) {
          if (!subtitleAnswerTC) {
            subtitleAnswerTC = true;
            return (
              <>
                {displaySubTitle("tracesHistory:subtitleAnswerTC")}
                {traceConditions(key, value)}
              </>
            );
          } else {
            return traceConditions(key, value);
          }
        } else if (key === "tags" && value !== undefined && value?.length > 0) {
          return traceTags(key, value);
        } else if (
          !keysNotShow.includes(key) &&
          value !== undefined &&
          value?.length > 0
        ) {
          return traceText(key, value);
        }
      } else return null;
    });
  }

  return (
    <Card
      sx={{
        width: "95%",
        marginTop: 1,
        marginLeft: "2.5%",
        boxShadow: "4",
        borderRadius: "5px",
      }}>
      <CardHeader
        avatar={
          <Box
            sx={{
              borderColor: "black",
              borderStyle: "solid",
              borderWidth: 1,
              width: 20,
              height: 20,
              bgcolor: color,
              borderRadius: "50%",
            }}
          />
        }
        title={renderTitle(trace, types)}
        subheader={
          <>
            {date}
            <span style={{ marginLeft: "55px" }}></span>
            {trace.userName}
          </>
        }
        action={
          <div>
            {trace.type !== "delete" &&
            !(
              Object.keys(trace.changes)?.length === 2 && trace.changes.name
            ) ? (
                <ExpandMore
                  expand={expanded}
                  onClick={() => setExpanded(!expanded)}
                  aria-label="show more">
                  <ExpandMoreIcon />
                </ExpandMore>
              ) : null}
          </div>
        }></CardHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {trace.type !== "delete" ? (
            <div>
              <Typography paragraph>
                {t("tracesHistory:subtitlechanges")}
              </Typography>
              {renderChanges(trace)}
            </div>
          ) : null}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CardTrace;
