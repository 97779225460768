import React from "react";
import { useRef, useEffect } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { get } from "../../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function ChipInput({ ...props }) {
  const [inputValue, setInputValue] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const ref = useRef(null);
  const [editFlag, setEditFlag] = React.useState(false);
  const [oldTag, setOldTag] = React.useState("");
  const [isMaxLengthReached, setIsMaxLengthReached] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation("chipInput");

  useEffect(() => {
    if (props.value && props.value.length && props.value.length > 0) {
      setTags(props.value);
    } else {
      setTags([]);
    }
  }, []);

  useEffect(() => {
    if (props.value && props.value.length && props.value.length > 0) {
      setTags(props.value);
    } else {
      setTags([]);
    }
  }, [props.value]);

  async function handleKeyDown(event) {
    const key = props.newChipKeys ? props.newChipKeys : [";"];
    if (
      (key.includes(event.key) || key.includes(event.code)) &&
      inputValue !== "" &&
      !isMaxLengthReached
    ) {
      event.preventDefault();
      const newSelectedItem = [...tags];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim(),
      );

      if (duplicatedValues !== -1) {
        setInputValue("");
        if (editFlag) {
          setOldTag("");
          setEditFlag(false);
          setTags(newSelectedItem);
          props.updateProps(newSelectedItem);
        }
        return;
      }

      // if (!event.target.value.replace(/\s/g, "").length) return;
      if (editFlag) {
        let index = tags.indexOf(oldTag);
        newSelectedItem.splice(index, 1, event.target.value.trim());
      } else {
        newSelectedItem.push(event.target.value.trim());
      }
      setOldTag("");
      setEditFlag(false);
      setTags(newSelectedItem);
      props.updateProps(newSelectedItem);
      setInputValue("");
      ref.current.value = "";

      if (props.getValidation && props.getValidation.get) {
        let question = newSelectedItem[newSelectedItem.length - 1];
        await getValidationQuestionIntent(
          props.getValidation.agentSelected,
          question,
        );
      }
    }

    let maxLengthReached = inputValue.length >= 1045;
    setIsMaxLengthReached(maxLengthReached);

    if (props.answerLengthError) {
      props.lengthError(isMaxLengthReached);
    }

    if (event.key === "Enter") {
      setInputValue(event.target.value + "\n");
    }

    if (inputValue.length && !inputValue.length && event.key === "Backspace") {
      setTags(inputValue.slice(0, inputValue.length - 1));
    }
  }

  const getValidationQuestionIntent = async (agentSelected, e) => {
    await get("/intents/validatequestion/" + agentSelected + "/" + e)
      .then(() => {})
      .catch((error) => {
        const errorMessage = error.response.data.join(", ");
        enqueueSnackbar(t("questionError", { errorMessage }), {
          variant: "warning",
        });
      });
  };

  function handleEdit(index) {
    if (editFlag) {
      if (oldTag !== tags[index]) return;
      setInputValue("");
      setEditFlag(false);
      setOldTag("");
    }

    let maxLengthReached = inputValue.length >= 1045;
    setIsMaxLengthReached(maxLengthReached);

    if (props.answerLengthError) {
      props.lengthError(isMaxLengthReached);
    }

    if (inputValue !== "") return;
    setInputValue(tags[index]);
    setOldTag(tags[index]);
    setEditFlag(true);
  }

  function handleDelete(index) {
    if (editFlag) return;
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    props.updateProps(newTags);
  }

  function handleClear() {
    setTags([]);
    setInputValue("");
    props.updateProps([]);
  }

  return (
    <React.Fragment>
      <Autocomplete
        sx={props.sx}
        multiple
        id="tags-filled"
        options={[]}
        freeSolo
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        disabled={props.disabled}
        value={tags}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
          if (event && event.type === "blur") {
            setInputValue("");
          } else if (reason === "clear") {
            handleClear();
          } else if (reason !== "reset") {
            setInputValue(value);
          }
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              sx={{ color: props.fontColor || "inherit" }}
              color={oldTag === option ? "primary" : "default"}
              variant={
                oldTag === option && editFlag === true ? "filled" : "outlined"
              }
              label={option.split("\n").map((text, index) => (
                <div key={`${text}-${index}`}>
                  {text}
                  <br />
                </div>
              ))}
              {...getTagProps({ index })}
              onDelete={() => handleDelete(index)}
              onClick={() => handleEdit(index)}
              deleteIcon={
                oldTag === option && editFlag ? (
                  <EditIcon
                    style={{
                      color: props.fontColor || "inherit",
                      fontSize: "1rem",
                    }}
                  />
                ) : (
                  <DeleteIcon
                    style={{
                      color: props.fontColor || "inherit",
                      fontSize: "1rem",
                    }}
                  />
                )
              }
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            ref={ref}
            {...params}
            variant="filled"
            style={{ backgroundColor: "rgba(213, 213, 231, 0.2)" }}
            multiline
            label={props.label}
            placeholder={props.placeholder}
            value={inputValue}
            error={props.error}
            helperText={props.error}
          />
        )}
      />
    </React.Fragment>
  );
}

ChipInput.defaultProps = {
  tags: [],
};
