import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from "victory";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import {
  changeNameChannels,
  channelsList,
} from "../../shared/helper/analyticsHelper";
import { acumulateObject } from "../../shared/helper/validations";
import { get } from "../../shared/http/httpService";
import ChartTheme from "../theme/theme";
import { mkConfig, generateCsv, download } from "export-to-csv";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
  },
  button: {
    backgroundColor: "#D9D9D9",
    color: "black",
    height: "40px",
    width: "100px",
    borderRadius: "5px",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));
const ConversationsPerDay = () => {
  const classes = useStyles();
  let chartTheme = ChartTheme();

  const { t } = useTranslation();

  const [valid, setValid] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [dataGraph, setDataGraph] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [total, setTotal] = useState(0);
  const [channelsSelected, setChannelsSelected] = useState([
    "MESSENGER",
    "WHATSAPP",
    "INSTAGRAMDIRECT",
    "WEBCHAT",
    "WEB",
    "msteams",
  ]);
  const [allChannelLabel, setAllChannelLabel] = useState(
    t("conversationPerDay:all"),
  );
  const [fileName, setFileName] = useState(
    t("analytics:conversationsPerDayFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );
  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((item) => {
      let channel = item.z;
      switch (item.z) {
        case "MESSENGER":
          channel = "FACEBOOK";
          break;
        case "INSTAGRAMDIRECT":
          channel = "INSTAGRAM";
          break;
        case "msteams":
          channel = "TEAMS";
          break;
        case "WHATSAPP":
          channel = "WHATSAPP";
          break;
        case "WEBCHAT":
          channel = "WEBCHAT";
          break;
        default:
          channel = "WEB";
          break;
      }
      return {
        [t("analytics:date")]: item.x,
        [t("analytics:count")]: item.y,
        [t("conversationPerDay:channel")]: channel,
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const fetchDataConversation = async () => {
    // eslint-disable-next-line no-useless-escape
    const timezone = startDate.format("Z");
    const conversationPerDayTmp = await get("/report/conversationsPerDay", {
      startDate: dayjs(startDate).toDate(),
      endDate: dayjs(endDate).toDate(),
      channelsSelected: channelsSelected,
      timezone,
    });
    //conversationPerDayTmp array with date,chanel,count
    const newData = conversationPerDayTmp.map((conversation) => {
      return {
        x: dayjs(conversation.date).format("DD/MM/YYYY"),
        y: conversation.count,
        z: conversation.channel,
        label: `${conversation.count} convesation/s on the day: ${conversation.date}`,
      };
    });
    const dataGraph = acumulateObject(newData, "x", "y");
    setDataGraph(dataGraph);

    setTotal(
      newData.reduce((accumulator, object) => {
        return accumulator + object.y;
      }, 0),
    );
    setData(newData);
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);
      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:conversationsPerDayFile") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  function handleChannels(channels) {
    if (channels.includes(t("conversationPerDay:all"))) {
      if (channelsSelected.length < 6) {
        setChannelsSelected([
          "MESSENGER",
          "WHATSAPP",
          "INSTAGRAMDIRECT",
          "WEBCHAT",
          "WEB",
          "msteams",
        ]);
      } else {
        setChannelsSelected([]);
        setAllChannelLabel(t("conversationPerDay:all"));
      }
    } else {
      setAllChannelLabel(t("conversationPerDay:all"));
      setChannelsSelected(channels);
    }
  }

  return (
    <Container maxwidth="lg">
      <Paper
        style={{
          marginBottom: "15px",
          paddingTop: "10px",
          paddingBottom: "15px",
          paddingLeft: "5px",
        }}>
        <Typography variant="h4">
          {t("analytics:conversationPerDay")}
        </Typography>
        <br></br>
        <Grid container justify="space-around" alignItems="center">
          <DateRange
            startDate={startDate}
            endDate={endDate}
            onDateSelected={handleDate}
          />
          <FormControl
            sx={{ minWidth: 130, maxWidth: 150, margin: "0px 5px 0px 5px" }}>
            <InputLabel>{t("conversationPerDay:channel")}</InputLabel>
            <Select
              label={t("conversationPerDay:channel")}
              onChange={(e) => {
                handleChannels(e.target.value);
              }}
              multiple
              value={channelsSelected}
              defaultValue={[
                "MESSENGER",
                "WHATSAPP",
                "INSTAGRAMDIRECT",
                "msteams",
                "WEBCHAT",
                "WEB",
                "msteams",
              ]}
              renderValue={(selected) => {
                if (selected.length === 6) {
                  setAllChannelLabel(t("conversationPerDay:deselectAll"));
                  return t("conversationPerDay:all");
                } else {
                  return changeNameChannels(selected).join(", ");
                }
              }}>
              <MenuItem
                value={t("conversationPerDay:all")}
                sx={{ fontStyle: "italic" }}>
                <Checkbox
                  checked={channelsSelected.length === 6}
                  indeterminate={
                    channelsSelected.length > 0 && channelsSelected.length < 6
                  }
                />
                {allChannelLabel}
              </MenuItem>
              {channelsList.map((channel) => (
                <MenuItem value={channel.value} key={channel.value}>
                  <Checkbox
                    checked={channelsSelected.indexOf(channel.value) > -1}
                  />
                  {channel.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginLeft: "10px",
            }}>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              size="medium"
              disabled={!valid || channelsSelected.length === 0}
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}
              onClick={fetchDataConversation}>
              {t("conversationPerDay:fetch")}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              disabled={data.length === 0 || channelsSelected.length === 0}
              onClick={() => {
                prepareDataForExport(data);
              }}
              size="medium"
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}>
              {t("conversationPerDay:download")}
            </Button>
          </div>
        </Grid>
        <VictoryChart
          fixLabelOverlap={true}
          responsive={false}
          height={300}
          width={400}
          animate={{
            duration: 500,
            onLoad: { duration: 200 },
          }}
          theme={chartTheme}
          domainPadding={{ x: 30 }}
          padding={80}>
          <VictoryBar
            barRatio={1}
            cornerRadius={{ top: 3 }}
            style={{
              data: {
                fill: "#B474DC",
              },
              labels: {
                fontSize: 5,
                fill: "black",
              },
            }}
            alignment="middle"
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                pointerLength={0}
                flyoutStyle={{
                  stroke: "grey",
                  backgroundColor: "red",
                }}
              />
            }
            data={dataGraph}
          />
          <VictoryAxis
            label={t("conversationPerDay:date")}
            style={{
              axisLabel: { padding: 30 },
              tickLabels: { angle: -65, fontSize: 5 },
            }}
          />
          <VictoryAxis
            dependentAxis
            label={t("conversationPerDay:amountOfConversations")}
            style={{
              axisLabel: { padding: 50 },
            }}
          />
        </VictoryChart>
        <Typography variant="h6">
          {t("conversationPerDay:totalConversations")}: {total}
        </Typography>
      </Paper>
    </Container>
  );
};

export default ConversationsPerDay;
