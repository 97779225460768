import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";

const DateRange = (props) => {
  const minDate =
    props.allowFuture && props.allowFuture === true ? dayjs() : null;
  const [maxDate, setMaxDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [minStartDate, setMinStartDate] = useState();
  const [dates, setDates] = useState({
    startDate: props.startDate
      ? dayjs(props.startDate).startOf("day")
      : dayjs().startOf("day"),
    endDate: props.endDate
      ? dayjs(props.endDate).endOf("day")
      : dayjs().endOf("day"),
  });
  const [validDates, setValidDates] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (props.allowFuture) {
      dates.endDate
        ? setMaxDate(null)
        : setMaxDate(dayjs(dates.endDate).endOf("day"));
      setMaxStartDate(dates.endDate);
    } else {
      setMaxDate(dayjs().endOf("day"));
      setMaxStartDate(dates.endDate > dayjs() ? dayjs() : dates.endDate);
    }

    if (props.maxOneWeek && dates.endDate) {
      setMinStartDate(dayjs(dates.endDate).subtract(7, "day"));
    } else {
      setMinStartDate(null);
    }

    checkValid();
  }, [dates.startDate, dates.endDate]);

  const checkValid = () => {
    const tempValidDates = {
      startDate: validDates.startDate,
      endDate: validDates.endDate,
      range: validDates.range,
    };
    const temporalDate = {
      startDate: dates.startDate,
      endDate: dates.endDate,
      valid: true,
    };
    tempValidDates.startDate = validDate(dates.startDate);
    tempValidDates.endDate = validDate(dates.endDate);
    tempValidDates.range = withinRange(dates.startDate, dates.endDate);
    setValidDates(tempValidDates);
    if (
      !tempValidDates.startDate ||
      !tempValidDates.endDate ||
      !tempValidDates.range
    ) {
      temporalDate.valid = false;
    } else {
      temporalDate.valid = true;
    }

    props.onDateSelected(temporalDate);
    if (
      props.setError &&
      (!tempValidDates.startDate ||
        !tempValidDates.endDate ||
        !tempValidDates.range)
    ) {
      const errors = { ...props.errors, date: "error" };
      props.setError(errors);
    } else if (props.setError) {
      const errors = { ...props.errors };
      delete errors.date;
      props.setError(errors);
    }
  };

  const formatAndCheckDate = (date) => {
    if (!date) return null;

    return dayjs.isDayjs(date) ? date : dayjs(date);
  };

  const updateDateStart = (startDate) => {
    startDate = formatAndCheckDate(startDate);
    startDate =
      typeof startDate === "string"
        ? dayjs(new Date(startDate))
        : startDate
          ? dayjs(startDate)
          : null;

    let tempDates = {
      startDate: startDate,
      endDate: dates.endDate,
    };
    setDates(tempDates);
  };

  const updateDateEnd = (endDate) => {
    endDate = formatAndCheckDate(endDate);
    endDate =
      typeof endDate === "string"
        ? dayjs(new Date(endDate))
        : endDate
          ? dayjs(endDate)
          : null;

    let tempDates = {
      startDate: dates.startDate,
      endDate: endDate,
    };
    setDates(tempDates);
  };

  function validDate(date) {
    const currentDate = dayjs();
    if (props.allowFuture && props.allowFuture === true) {
      return (
        date !== null &&
        (date?.isAfter(currentDate, "day") || date?.isSame(currentDate, "day"))
      );
    } else {
      let response = dayjs(date).isValid();
      return response;
    }
  }

  function withinRange(startDate, endDate) {
    if (!startDate || !endDate) return false;
    if (startDate > endDate) {
      return false;
    }
    if (
      !props.allowFuture &&
      (startDate > dayjs().endOf("day") || endDate > dayjs().endOf("day"))
    ) {
      return false;
    }
    if (props.maxOneWeek && endDate.diff(startDate, "day") > 7) {
      return false;
    }
    return true;
  }

  const getLanguage = () => {
    const storedLanguage = localStorage.getItem("lang");
    switch (storedLanguage) {
      case "ES":
        return "es";
      default:
        return "en";
    }
  };

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={getLanguage()}>
        <div style={{ display: "flex" }}>
          <div style={{ margin: "5px" }}>
            <DesktopDatePicker
              label={t("dateRange:startDate")}
              format="DD/MM/YYYY"
              minDate={minStartDate || minDate}
              maxDate={maxStartDate}
              value={dates.startDate}
              onChange={(e) => updateDateStart(e ? e.toDate() : null)}
              disabled={props.disabled}
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                    error={!validDates.startDate || !validDates.range}
                  />
                </div>
              )}
            />
          </div>
          <div style={{ margin: "5px" }}>
            <DesktopDatePicker
              label={t("dateRange:endDate")}
              format="DD/MM/YYYY"
              minDate={dates.startDate}
              maxDate={maxDate}
              value={dates.endDate ? dayjs(dates.endDate) : ""}
              onChange={(e) => updateDateEnd(e ? e.toDate() : null)}
              disabled={props.disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!validDates.endDate || !validDates.range}
                />
              )}
            />
          </div>
        </div>
        <span
          style={{
            color: "red",
            fontSize: 12,
            display: "block",
            margin: "0px 5px 0px 5px",
          }}>
          {!validDates.startDate || !validDates.endDate || !validDates.range
            ? t("dateRange:errorSelectionDate")
            : ""}
        </span>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default React.memo(DateRange);